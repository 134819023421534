<!--
 * @Description  : 
 * @Author       : BigBigger
 * @Date         : 2021-11-12 09:46:59
 * @LastEditTime : 2022-07-20 13:27:17
 * @LastEditors  : BigBigger
-->
<template>
  <div class="step1">
    <!-- 地区选择 -->
    <div class="signup-item">
      <div class="item-label">地区选择</div>
      <div class="item-list">
        <div class="i-item" :class="{'i-item-active': jobActived === item}" v-for="(item, index) of jobPlaceList" :key="index" @click="placeClick(item)">{{item}}</div>
        <!-- <div class="i-item">全部</div>
        <div class="i-item i-item-active">全部</div> -->
      </div>
    </div>
    <div class="signup-item">
      <div class="item-label">职位搜索</div>
      <div class="item-list">
        <el-select v-model="jobkey" size="small" style="margin-right: 25px; width:218px;" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.key"
            :label="item.label"
            :value="item.key">
          </el-option>
        </el-select>
        <el-input :clearable="true" v-model="jobvalue" size="small" style="margin-right: 25px; width:218px;" prefix-icon="el-icon-search" placeholder="请输入内容"></el-input>
        <!-- <el-button size="small" type="primary">搜索</el-button> -->
        <button class="search" @click="onSearch">搜索</button>
      </div>
    </div>
      <!-- 表格 -->
    <div class="signup-table">
      <el-table
      :data="tableData"
      :header-row-class-name="theadName"
      style="width: 100%">
        <el-table-column
          prop="departmentName"
          label="部门名称"
          align="center"
          :show-overflow-tooltip="true"
          width="200">
        </el-table-column>
        <el-table-column
          prop="employer"
          label="单位名称"
          align="center"
          :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column
          prop="positionName"
          label="职位名称"
          align="center"
          :show-overflow-tooltip="true"
          width="180">
        </el-table-column>
        <el-table-column
          prop="numberCandidates"
          align="center"
          label="招聘人数"
          width="90">
        </el-table-column>
        <el-table-column
          prop="jobCode"
          align="center"
          label="职位代码"
          width="180">
        </el-table-column>
        <!-- <el-table-column
          prop="p5"
          label="岗位详情">
        </el-table-column> -->
        <el-table-column
          align="center"
          label="岗位详情"
          width="100">
          <template label="岗位详情" slot-scope="scope">
            <span class="job-detail" @click="showJobDetail(scope.row)">查看详情</span>
          </template>
        </el-table-column>
      
        <el-table-column
          align="center"
          label="选择"
          width="150">
          <template label="岗位详情" slot-scope="scope">
            <button slot="reference" class="select-btn" @click="confirmSelect(scope.row)">选择</button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页器 -->
      <div class="sign-pagination-box">
        <el-pagination
          background
          layout="total,prev, pager, next, jumper"
          @current-change="currentPageChange"
          :current-page="page"
          :page-size="limit"
          :total="total">
        </el-pagination>
      </div>
    </div>
      <!-- 职位弹窗 -->
    <el-dialog
      :visible.sync="dialogVisible"
      width="810px">
      <p class="dialog-title" slot="title">岗位详情</p>
      <div class="dialog-body" v-if="jobDetail">
        <el-row>
          <el-col :span="24" v-if="jobDetail.employer">
            <div class="dialog-item">
              <div class="dialog-label">
                <span>单位名称</span>
              </div>
              <div class="dialog-content">
                <span>{{jobDetail.employer}}</span>
              </div>
            </div>
          </el-col>
          <el-col :span="24" v-if="jobDetail.institutionalAttributes">
            <div class="dialog-item">
              <div class="dialog-label">
                <span>机构属性</span>
              </div>
              <div class="dialog-content">
                <span>{{jobDetail.institutionalAttributes}}</span>
              </div>
            </div>
          </el-col>
          <el-col :span="24" v-if="jobDetail.positionName">
            <div class="dialog-item">
              <div class="dialog-label">
                <span>职位名称</span>
              </div>
              <div class="dialog-content">
                <span>{{jobDetail.positionName}}</span>
              </div>
            </div>
          </el-col>
          <el-col :span="24" v-if="jobDetail.jobCode">
            <div class="dialog-item">
              <div class="dialog-label">
                <span>职位代码</span>
              </div>
              <div class="dialog-content">
                <span>{{jobDetail.jobCode}}</span>
              </div>
            </div>
          </el-col>
          <el-col :span="24" v-if="jobDetail.jobProfile">
            <div class="dialog-item">
              <div class="dialog-label">
                <span>职位简介</span>
              </div>
              <div class="dialog-content">
                <span>{{jobDetail.jobProfile}}</span>
              </div>
            </div>
          </el-col>
          <el-col :span="24" v-if="jobDetail.examCategory">
            <div class="dialog-item">
              <div class="dialog-label">
                <span>考试类型</span>
              </div>
              <div class="dialog-content">
                <span>{{jobDetail.examCategory}}</span>
              </div>
            </div>
          </el-col>
          <el-col :span="24" v-if="jobDetail.numberCandidates">
            <div class="dialog-item">
              <div class="dialog-label">
                <span>招考人数</span>
              </div>
              <div class="dialog-content">
                <span>{{jobDetail.numberCandidates}}</span>
              </div>
            </div>
          </el-col>
          <el-col :span="24" v-if="jobDetail.profession">
            <div class="dialog-item">
              <div class="dialog-label">
                <span>专业</span>
              </div>
              <div class="dialog-content">
                <span>{{jobDetail.profession}}</span>
              </div>
            </div>
          </el-col>
          <el-col :span="24" v-if="jobDetail.education">
            <div class="dialog-item">
              <div class="dialog-label">
                <span>学历</span>
              </div>
              <div class="dialog-content">
                <span>{{jobDetail.education}}</span>
              </div>
            </div>
          </el-col>
          <el-col :span="24" v-if="jobDetail.degree">
            <div class="dialog-item">
              <div class="dialog-label">
                <span>学位</span>
              </div>
              <div class="dialog-content">
                <span>{{jobDetail.degree}}</span>
              </div>
            </div>
          </el-col>
          <el-col :span="24" v-if="jobDetail.minimumYearsGrassrootsWork">
            <div class="dialog-item">
              <div class="dialog-label">
                <span>工作最低年限</span>
              </div>
              <div class="dialog-content">
                <span>{{jobDetail.minimumYearsGrassrootsWork}}</span>
              </div>
            </div>
          </el-col>
          <el-col :span="24" v-if="jobDetail.otherConditions">
            <div class="dialog-item">
              <div class="dialog-label">
                <span>其他条件</span>
              </div>
              <div class="dialog-content">
                <span>{{jobDetail.otherConditions}}</span>
              </div>
            </div>
          </el-col>
          <el-col :span="24" v-if="jobDetail.remarks">
            <div class="dialog-item">
              <div class="dialog-label">
                <span>备注</span>
              </div>
              <div class="dialog-content">
                <span>{{jobDetail.remarks}}</span>
              </div>
            </div>
          </el-col>
          <el-col :span="24" v-if="jobDetail.areaName">
            <div class="dialog-item">
              <div class="dialog-label">
                <span>地区名称</span>
              </div>
              <div class="dialog-content">
                <span>{{jobDetail.areaName}}</span>
              </div>
            </div>
          </el-col>
          <el-col :span="24" v-if="jobDetail.age">
            <div class="dialog-item">
              <div class="dialog-label">
                <span>年龄</span>
              </div>
              <div class="dialog-content">
                <span>{{jobDetail.age}}</span>
              </div>
            </div>
          </el-col>
          <el-col :span="24" v-if="jobDetail.gender">
            <div class="dialog-item">
              <div class="dialog-label">
                <span>性别</span>
              </div>
              <div class="dialog-content">
                <span>{{jobDetail.gender}}</span>
              </div>
            </div>
          </el-col>
          <el-col :span="24" v-if="jobDetail.phone">
            <div class="dialog-item">
              <div class="dialog-label">
                <span>电话</span>
              </div>
              <div class="dialog-content">
                <span>{{jobDetail.phone}}</span>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getJobPlaceName, getJobList, checkBeforeCreate, checkFully } from '@/api/api'
import { JOB_TYPE_QUERY } from '@/config/config'

export default {
  name: "Step1",
  data() {
    return {
      jobkey: 'DEPARTMENTNAME',
      jobvalue: '',
      options: JOB_TYPE_QUERY,
      jobDetail: null,
      dialogVisible: false,
      jobPlaceList: [],
      jobActived: "全部",
      page: 1,
      limit: 5,
      total: 0,
      tableData: []
    }
  },
  methods: {
    currentPageChange(page) {
      this.page = page
      this.getJobList()
    },
    // 搜索职位列表
    async onSearch() {
      this.page = 1
      this.getJobList()
      // let response = await onSearch()
    },
    async getJobList() {
      let params = {
        type: this.jobkey,
        val: this.jobvalue,
        page: this.page,
        limit: this.limit
      }
      let orderInfo = this.$store.state.orderInfo

      if(orderInfo) {
        params.jobListId = this.orderInfo.jobListId
      }
      if(this.jobActived !== '全部') {
        params.placeName = this.jobActived
      }
      let response =  await getJobList(params)
      if(+response.returnCode !== 10001) {
        this.$message.error(response.returnMsg)
        return false
      }
      this.total = response.total
      this.tableData = response.data
    },
    showJobDetail(val) {
      this.jobDetail = val;
      this.dialogVisible = true;
    },
    async checkFully(val) {
      let params = {
        jobTableId: val.id
      }
      let response = await checkFully(params)
      if(+response.returnCode !== 10001) {
        this.$message.error(response.returnMsg)
        return false
      }
      // console.log('>>', Object.assign({}, this.$store.state.orderInfo,{
      //   loJboInfo:val,
      // }))
      // return false
      this.$store.dispatch('setOrderInfo',Object.assign({}, this.$store.state.orderInfo,{
        loJboInfo:val,
      }))
      this.$router.replace({path: `/course/signup?step=2`})
    },
    async checkBeforeCreate(val) {
      let courseGroupId = ''
      if(this.orderInfo.loCourseInfo) {
        courseGroupId = this.orderInfo.loCourseInfo.id
      }
      let params = {
        courseGroupId: courseGroupId
      }
      let response = await checkBeforeCreate(params)
      if(+response.returnCode !== 10001) {
        this.$message.error(response.returnMsg)
        return false
      }
      this.$store.dispatch('setOrderInfo',{
        loJboInfo:val,
      })
      this.$router.replace({path: `/course/signup?step=2`})
    },
    confirmSelect(val) {
      // this.checkBeforeCreate(val)
      this.checkFully(val)
    },
    theadName() {
      return 'thead-class'
    },
    async getJobPlaceName() {
      let params = {
        jobListId: this.orderInfo.jobListId
      }
      let response = await getJobPlaceName(params)
      if(+response.returnCode !== 10001) {
        return false
      }
      this.jobPlaceList = [...this.jobPlaceList, ...response.data]
      this.getJobList()
    },
    placeClick(item) {
      this.jobActived = item
      this.page = 1
      this.$nextTick(() => {
        this.getJobList()
      })
    },
  },
  mounted() {
    this.orderInfo = this.$store.state.orderInfo || null
    this.$nextTick(() => {
      this.getJobPlaceName()
    })
  }
}
</script>

<style lang="less" scoped>
.signup-item{
  min-height: 35px;
  display: flex;
  margin-bottom: 22px;
  .item-label{
    font-size: 14px;
    font-weight: bold;
    color: #333333;
    line-height: 32px;
    width: 90px;
    flex-grow: 0;
    flex-shrink: 0;
  }
  .item-list{
    display: flex;
    flex-wrap: wrap;
    .i-item{
      min-width: 78px;
      padding: 0 10px;
      height: 32px;
      line-height: 32px;
      border: 1px solid #DBDBDB;
      border-radius: 4px;
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      text-align: center;
      cursor: pointer;
      margin-right: 25px;
      margin-bottom: 10px;
    }
    .i-item-active{
      color: #fff;
      background: #FF3F47;
      border: 1px solid #FF3F47;
    }
  }
}
.search{
    width: 83px;
    height: 32px;
    line-height: 32px;
    background: #FF3F47;
    border-radius: 4px;
    font-size: 14px;
    font-weight: bold;
    color: #FEFEFE;
  }
.signup-table{
  width: 1128px;
  // height: 414px;
  background: #FFFFFF;
  border: 1px solid #E8E8E8;
  border-radius: 4px;
  .sign-pagination-box{
    height: 101px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .s-thead{
    height: 56px;
    background: #F2F2F2;
    border-bottom: 1px solid #E8E8E8;
  }
}
.thead-class{
  background: #FF3F47;
}
.job-detail{
  cursor: pointer;
}
.dialog-title{
  font-size: 18px;
  font-weight: bold;
  color: #333333;
  text-align: center;
}
.dialog-body{
  border-top: 1px solid #DBDBDB;
  border-left: 1px solid #DBDBDB;
  .dialog-item{
    display: flex;
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    line-height: 24px;
    min-height: 55px;
    border-right: 1px solid #DBDBDB;
    border-bottom: 1px solid #DBDBDB;
    .dialog-label{
      width: 95px;
      background: #f2f2f2;
      flex-grow: 0;
      flex-shrink: 0;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .dialog-content{
      flex-grow: 1;
      flex-shrink: 1;
      padding-left: 20px;
      display: flex;
      align-items: center;
    }
  }
}
.select-btn{
  width: 70px;
  height: 30px;
  border: 1px solid #FB4A3E;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  color: #FF3F47;
  line-height: 26px;
  text-align: center;
}

</style>
<style>
.el-table th>.cell{
  color:#333!important;
}
</style>