<template>
  <div class="edit-add">
    <el-form :model="addreddForm" :rules="addressRules" ref="addressForm" label-width="90px">
      <el-row>
        <el-col :span="24">
          <el-form-item label="所在地址:" prop="provinceName">
            <el-col :span="8">
              <el-select style="width:140px;" v-model="addreddForm.provinceName" @change="provinceChange" placeholder="请选择">
                <el-option
                  v-for="item in provinceList"
                  :key="item.name"
                  :label="item.name"
                  :value="item.name">
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="8">
              <el-select style="width:140px;" :disabled="cityNameDisable" v-model="addreddForm.cityName" @change="cityChange" placeholder="请选择">
                <el-option
                  v-for="item in cityList"
                  :key="item.name"
                  :label="item.name"
                  :value="item.name">
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="8">
              <el-select style="width:140px;" :disabled="regionNameDisable" v-model="addreddForm.regionName" placeholder="请选择">
                <el-option
                  v-for="item in regionList"
                  :key="item.name"
                  :label="item.name"
                  :value="item.name">
                </el-option>
              </el-select>
            </el-col>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="详细地址:" prop="detailAddress">
            <el-input v-model="addreddForm.detailAddress"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="收货人:" prop="name">
            <el-input v-model="addreddForm.name"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="电话号码:" prop="phone">
            <el-input v-model="addreddForm.phone"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div class="dialog-btn">
      <el-button style="width:137px;" type="primary" @click="saveAddress">保存地址</el-button>
      <el-button style="width:137px;" @click="cancel">取消</el-button>
    </div>
  </div>
</template>

<script>
import province from '@/utils/province.json'
import { addAddress, updateAddress } from '@/api/api'
export default {
  name: 'AddressCreate',
  props: {
    detail: {
      type: Object,
      default: () => {
        return null
      }
    }
  },
  data() {
    return {
      addressRules: {
        phone: [
          {required: true, message: '请输入电话', trigger: 'blur'},
          {
            pattern: /^1[34578]\d{9}$/,
            message: "请填写正确的手机号码", trigger: "blur"
          }
        ],
      },
      options: [],
      addreddForm: {
        name: null,
        phone: null,
        provinceName: null,
        cityName: null,
        regionName: null,
        detailAddress: null,
      },
      provinceList: [],
      cityList: [],
      regionList: [],
      type: 0, // 0 编辑 1 新增
    }
  },
  mounted() {
    this.initArea()
    this.type = this.detail ? 0 : 1
    if(this.type === 0) {
      this.addreddForm.name = this.detail.name
      this.addreddForm.phone = this.detail.phone
      this.addreddForm.provinceName = this.detail.provinceName
      this.addreddForm.cityName = this.detail.cityName
      this.addreddForm.regionName = this.detail.regionName
      this.addreddForm.detailAddress = this.detail.detailAddress
    }
  },
  computed: {
    cityNameDisable() {
      return !this.addreddForm.provinceName
    },
    regionNameDisable() {
      return !this.addreddForm.cityName
    }
  },
  methods: {
    saveAddress() {
      this.$refs.addressForm.validate((valid) => {
        if (!valid) {
          return false
        }
        if(this.type === 0) {
          this.updateAddress()
        } else {
          this.addAddress()
        }
      })
    },
    async updateAddress() {
      let params = {
        id: this.detail.id,
        ...this.addreddForm
      }
      let response = await updateAddress(params)
      if(+response.returnCode !== 10001) {
        this.$$message.error(response.returnMsg)
        return false
      }
      this.$emit('onSuccess')
    },
    async addAddress() {
      let params = this.addreddForm
      let response = await addAddress(params)
      if(+response.returnCode !== 10001) {
        this.$message.error(response.returnMsg)
        return false
      }
      this.$emit('onSuccess')
    },
    cancel() {
      this.$emit('onCancel')
    },
    initArea() {
      this.addreddForm.provinceName = ''
      this.addreddForm.cityName = ''
      this.addreddForm.regionName = ''
      let provinceList = []
      province.forEach(item => {
        provinceList.push({name: item.name})
      });
      this.provinceList = provinceList
      this.cityList = []
      this.regionList = []
    },
    provinceChange(val) {
      this.addreddForm.cityName = ''
      this.addreddForm.regionName = ''
      this.regionList = []
      let cityList = []
      for(let item of province) {
        if(val === item.name) {
          cityList = item.city
          break
        }
      }
      this.cityList = cityList
    },
    cityChange(val) {
      this.addreddForm.regionName = ''
      let cityList = []
      this.regionList = []
      for(let item of province) {
        if(this.addreddForm.provinceName === item.name) {
          cityList = item.city
        }
      }
      let regionList = []
      for(let item of cityList) {
        if(val === item.name) {
          regionList = item.area
        }
      }
      regionList.forEach((item) => {
        this.regionList.push({name: item})
      })
    }
  }
}
</script>
<style lang="less" scoped>
.dialog-btn{
  display: flex;
  justify-content: center;
}
</style>