<template>
  <div class="step4">
    <!-- <div class="pay-code">
      <p class="order-no">订单编号：XXXXXXXXXXXXXXXXXX</p>
      <div class="pay-title">
        <span>需支付金额：</span>
        <span class="color-red">￥23113141421</span>
      </div>
      <div class="qrcode"></div>
    </div> -->
    <div class="pay-state">
      <img class="state-icon" src="@/assets/images/icon/success.png" alt="">
      <p class="pay-tip">购买成功</p>
      <p class="confirm-tip">{{tipList.paySuccessPrompt}}</p>
      <!-- <button class="btn" @click="toOrderDetail">查看订单</button> -->
      <button class="btn" @click="toCourseDetail">查看课程详情</button>
    </div>
  </div>
</template>

<script>
import { getOrderDetail, getLessonGroupTips } from '@/api/api'
export default {
  name: "Step4",
  data() {
    return {
      orderId: '',
      orderDetail: null,
      tipList:{}, // 审核提示语
    }
  },
  mounted() {
    this.orderId = this.$route.query.orderId
    this.getOrderDetail()

  },
  methods: {
    toCourseDetail(){
      this.$router.replace({path: `/course/${this.orderDetail.courseGroupId}`})
    },
    timeJump() {
      window.setTimeout(() => {
        this.toCourseDetail()
      }, 5000)
    },

    async getOrderDetail() {
      let params = {
        orderId: this.orderId
      }
      let response = await getOrderDetail(params);
      if(+response.returnCode !== 10001) {
        this.$message.error(response.returnMsg)
        return false
      }
      this.orderDetail = response.data
      this.getLessonGroupTips(this.orderDetail.courseGroupId)
      this.timeJump()
      },
    async getLessonGroupTips(courseGroupId) {
      let response = await getLessonGroupTips({courseGroupId})
      this.tipList = response.data
    }
  }
}
</script>

<style lang="less" scoped>
.step4{
  
  .pay-code{
    background: #FFFFFF;
    border: 1px solid #E8E8E8;
    border-radius: 4px;
    padding: 35px 30px;
    .order-no{
      height: 14px;
      font-size: 14px;
      font-weight: bold;
      color: #5B6B73;
      line-height: 14px;
      margin-bottom: 27px;
    }
    .pay-title{
      height: 17px;
      font-size: 16px;
      font-weight: 400;
      color: #333333;
      line-height: 14px;
      text-align: center;
      margin-bottom: 36px;
    }
    .qrcode{
      width: 168px;
      height: 215px;
      background: #FFFFFF;
      border: 1px solid #E6E6E6;
      margin: 0 auto;
    }
  }
}
.pay-state{
    width: 1130px;
    height: 339px;
    background: #FFFFFF;
    border: 1px solid #E8E8E8;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    .state-icon{
      display: block;
      width: 70px;
      height: 70px;
      // background: #eee;
      margin-bottom: 20px;
    }
    .pay-tip{
      font-size: 20px;
      font-weight: bold;
      color: #333333;
      line-height: 14px;
      margin-bottom: 29px;
    }
    .confirm-tip{
      font-size: 16px;
      font-weight: 400;
      color: #333333;
      line-height: 14px;
      margin-bottom: 30px;
    }
    .btn{
      width: 150px;
      height: 42px;
      line-height: 42px;
      background: #2E8DF4;
      border-radius: 4px;
      text-align: center;
      color: #FFFFFF;
    }
  }
.pay-bottom{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: #333;
  font-size: 16px;
  .confirm-btn{
    width: 148px;
    height: 40px;
    background: #FF3F47;
    border-radius: 6px;
    color: #fff;
    margin-left: 20px;
  }
}
</style>