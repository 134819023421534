<!--
 * @Description  : 
 * @Author       : BigBigger
 * @Date         : 2021-11-12 09:46:59
 * @LastEditTime : 2023-02-21 13:49:05
 * @LastEditors  : BigBigger
-->
<template>
	<div class="step3">
		<!-- 关联订单 -->
		<template v-if="orderDetail && orderDetail.type === 1">
			<template v-if="orderDetail">
				<!-- 待审核 -->
				<div
					class="pay-state"
					v-if="orderDetail.payInfo.confirmStatus === 1"
				>
					<img
						class="state-icon"
						src="@/assets/images/icon/waiting.png"
						alt="待审核"
					/>
					<p class="pay-tip">待审核，请耐心等待</p>
					<p class="confirm-tip">{{ tipList.submitAuditPrompt }}</p>
					<!-- <button class="btn" @click="toOrderDetail">查看订单</button> -->
					<button class="btn" @click="toCourseDetail">
						查看课程详情
					</button>
				</div>
				<!-- 等待中 -->
				<div
					class="pay-state"
					v-if="orderDetail.payInfo.confirmStatus === 3"
				>
					<img
						class="state-icon"
						src="@/assets/images/icon/waiting.png"
						alt="等待中"
					/>
					<p class="pay-tip">审核中，请耐心等待</p>
					<p class="confirm-tip">{{ tipList.waitPrompt }}</p>
					<!-- <button class="btn" @click="toOrderDetail">查看订单</button> -->
					<button class="btn" @click="toCourseDetail">
						查看课程详情
					</button>
				</div>
				<!-- 审核失败 -->
				<div
					class="pay-state"
					v-if="orderDetail.payInfo.confirmStatus === 4"
				>
					<img
						class="state-icon"
						src="@/assets/images/icon/fail.png"
						alt="审核失败"
					/>
					<p class="pay-tip">审核未通过，岗位已满</p>
					<p class="confirm-tip">{{ tipList.refusePrompt }}</p>
					<!-- <button class="btn" @click="toOrderDetail">查看订单</button> -->
					<button class="btn" @click="toCourseDetail">
						查看课程详情
					</button>
				</div>
				<!-- 审核通过 -->
				<template v-if="orderDetail.payInfo.confirmStatus === 2">
					<!-- 报名成功 -->
					<div
						class="pay-state"
						v-if="orderDetail.payInfo.orderStatus === 2"
					>
						<img
							class="state-icon"
							src="@/assets/images/icon/success.png"
							alt="报名成功"
						/>
						<p class="pay-tip">报名成功</p>
						<p class="confirm-tip">
							{{ tipList.paySuccessPrompt }}
						</p>
						<!-- <button class="btn" @click="toOrderDetail">查看订单</button> -->
						<button class="btn" @click="toCourseDetail">
							查看课程详情
						</button>
					</div>
					<!-- 其余状态 -->
					<div
						class="pay-state"
						v-if="orderDetail.payInfo.orderStatus !== 2"
					>
						<img
							class="state-icon"
							src="@/assets/images/icon/success.png"
							alt="审核通过"
						/>
						<p class="pay-tip">审核已通过</p>
						<p class="confirm-tip">{{ tipList.successPrompt }}</p>
						<!-- <button class="btn" @click="toOrderDetail">查看订单</button> -->
						<button class="btn" @click="toCourseDetail">
							查看课程详情
						</button>
					</div>
				</template>
			</template>
			<template
				v-if="
					orderDetail &&
					orderDetail.payInfo.confirmStatus === 2 &&
					orderDetail.payInfo.payStatus === 1 &&
					orderDetail.payInfo.orderStatus === 1
				"
			>
				<div class="pay-way">
					<p class="pay-tip">请选择支付方式</p>
          <div class="payway-list">
						<div
							class="payway-item"
							:class="{ 'payway-item-active': type === 2 }"
						>
							<p class="pi-tag"></p>
							<img
								src="@/assets/images/icon/alipay.png"
								alt="支付宝付款"
							/>
							<span>支付宝付款</span>
						</div>
						<!-- <div class="payway-item" :class="{'payway-item-active': type === 1}">
              <p class="pi-tag"></p>
              <img src="@/assets/images/icon/wechatpay.png" alt="微信付款">
              <span>微信付款</span>
            </div> -->
					</div>
					<div class="pay">
						<div
							class="pay-item"
							@click="orderDetail.payWay = 2"
							:class="{ 'pay-item-active': orderDetail.payWay === 2 }"
							v-if="
								[2,3].includes(+orderDetail.courseInfo.payWay)
							"
						>
							定金：￥{{ orderDetail.courseInfo.deposit }}
							<p class="tag"></p>
						</div>
						<div
							class="pay-item"
							@click="orderDetail.payWay = 1"
							:class="{ 'pay-item-active': orderDetail.payWay === 1 }"
							v-if="
								!(
									orderDetail.courseInfo.throughPay === 1 &&
									orderDetail.throughPay === 1
								) &&
								[1,2].includes(orderDetail.courseInfo.payWay)
							"
						>
							全款：￥{{getPrice}}
							<p class="tag"></p>
						</div>
						<div
							v-if="
								!(
									orderDetail.courseInfo.throughPay === 1 &&
									orderDetail.throughPay === 1
								) &&
								orderDetail.courseInfo &&
								orderDetail.courseInfo.payWay === 2
							"
						>
							推荐定金（报名现场支付课程余款）
						</div>
					</div>
				</div>
				<div class="pay-bottom" v-if="orderDetail">
					<span
						>（支付方式：{{
							orderDetail.payWay | paywayFilter
						}}）实付金额：</span
					>
					<span class="color-red"
						>￥{{
							+orderDetail.payWay === 1
								? orderDetail.courseInfo.payMoney
								: orderDetail.courseInfo.deposit
						}}</span
					>
					<!-- <button class="confirm-btn" @click="pay">立即支付</button> -->
					<el-button type="primary" @click="pay" :loading="loading"
						>立即支付</el-button
					>
				</div>
			</template>
		</template>
		<!-- 非关联顶顶那 -->
		<template v-if="orderDetail && orderDetail.type !== 1">
			<div class="pay-state" v-if="orderDetail.payInfo.orderStatus === 2">
				<img
					class="state-icon"
					src="@/assets/images/icon/success.png"
					alt="报名成功"
				/>
				<p class="pay-tip">支付成功</p>
				<p class="confirm-tip">{{ tipList.paySuccessPrompt }}</p>
				<!-- <button class="btn" @click="toOrderDetail">查看订单</button> -->
				<button class="btn" @click="toCourseDetail">
					查看课程详情
				</button>
			</div>
			<template v-if="orderDetail.payInfo.orderStatus !== 2">
				<div class="pay-way">
					<p class="pay-tip">请选择支付方式</p>
					<div class="payway-list">
						<div
							class="payway-item"
							:class="{ 'payway-item-active': type === 2 }"
						>
							<p class="pi-tag"></p>
							<img
								src="@/assets/images/icon/alipay.png"
								alt="支付宝付款"
							/>
							<span>支付宝付款</span>
						</div>
					</div>
          <div class="pay">
						<div
							class="pay-item"
							@click="orderDetail.payWay = 2"
							:class="{ 'pay-item-active': orderDetail.payWay === 2 }"
							v-if="
								[2,3].includes(+orderDetail.courseInfo.payWay)
							"
						>
							定金：￥{{ orderDetail.courseInfo.deposit }}
							<p class="tag"></p>
						</div>
						<div
							class="pay-item"
							@click="orderDetail.payWay = 1"
							:class="{ 'pay-item-active': orderDetail.payWay === 1 }"
							v-if="
								!(
									orderDetail.courseInfo.throughPay === 1 &&
									orderDetail.throughPay === 1
								) &&
								[1,2].includes(orderDetail.courseInfo.payWay)
							"
						>
							全款：￥{{getPrice}}
							<p class="tag"></p>
						</div>
						<div
							v-if="
								!(
									orderDetail.courseInfo.throughPay === 1 &&
									orderDetail.throughPay === 1
								) &&
								orderDetail.courseInfo &&
								orderDetail.courseInfo.payWay === 2
							"
						>
							推荐定金（报名现场支付课程余款）
						</div>
					</div>
				</div>
				<div class="pay-bottom" v-if="orderDetail">
					<span
						>（支付方式：{{
							orderDetail.payWay | paywayFilter
						}}）实付金额：</span
					>
					<span class="color-red"
						>￥{{
							+orderDetail.payWay === 1
								? getPrice
								: orderDetail.courseInfo.deposit
						}}</span
					>
					<el-button
						type="primary confirm-btn"
						@click="pay"
						:loading="loading"
						>立即支付</el-button
					>
				</div>
			</template>
		</template>
	</div>
</template>

<script>
import { PAYWAY } from '@/config/config';
import { getOrderDetail, getLessonGroupTips, pay } from '@/api/api';

export default {
	name: 'Step3',
	data() {
		return {
			orderId: '',
			orderDetail: null,
			type: 2, // 支付方式,1：微信 2：支付宝
			tipList: {}, // 审核提示语
			loading: false,
      payWay: 0,
		};
	},
	computed: {
		getPrice() {
			if (!this.orderDetail.courseInfo || !this.orderDetail.personalInfo)
				return '';
			if (
				this.orderDetail.personalInfo.studentsPreferential === 1 &&
				this.orderDetail.personalInfo
			) {
				return this.orderDetail.personalInfo.afterOldStudentsPrice;
			}
			return this.orderDetail.courseInfo.payMoney;
		},
	},
	mounted() {
		this.orderId = this.$route.query.orderId;
		this.getOrderDetail();
	},
	filters: {
		paywayFilter(val) {
			return PAYWAY[+val] || '';
		},
	},
	methods: {
		async pay() {
			this.loading = true;
			let params = {
				courseGroupId: this.orderDetail.courseGroupId,
				type: this.type,
				from: 1,
				orderCode: this.orderDetail.orderCode,
				orderId: this.orderDetail.orderId,
        payWay: this.orderDetail.payWay
			};
			let response = await pay(params);
			this.loading = false;
			if (+response.returnCode !== 10001) {
				this.$message.error(response.returnMsg);
				return false;
			}
			// window.location.href = `/mine?type=1`
			const div = document.createElement('divform');
			div.innerHTML = response.data;
			document.body.appendChild(div);
			// document.forms[0].acceptCharset='GBK';//保持与支付宝默认编码格式一致，如果不一致将会出现：调试错误，请回到请求来源地，重新发起请求，错误代码 invalid-signature 错误原因: 验签出错，建议检查签名字符串或签名私钥与应用公钥是否匹配
			document.forms[0].acceptCharset = 'UTF-8';
			document.forms[0].submit();
		},
		toOrderDetail() {
			this.$router.replace({
				path: `/mine/orderDetail?id=${this.orderDetail.orderId}`,
			});
		},
		toCourseDetail() {
			this.$router.replace({
				path: `/course/${this.orderDetail.courseGroupId}`,
			});
		},
		async getOrderDetail() {
			let params = {
				orderId: this.orderId,
			};
			let response = await getOrderDetail(params);
			if (+response.returnCode !== 10001) {
				this.$message.error(response.returnMsg);
				return false;
			}
			this.orderDetail = response.data;
      if (this.orderDetail.courseInfo.payWay === 1) {
        this.orderDetail.payWay = 1;
      }
      if (this.orderDetail.courseInfo.payWay === 3) {
        this.orderDetail.payWay = 2;
      }
      if (this.orderDetail.courseInfo.payWay === 2 && ![1,2].includes(this.orderDetail.payWay)) {
        this.orderDetail.payWay = 1;
      }
			this.getLessonGroupTips(this.orderDetail.courseGroupId);
		},
		async getLessonGroupTips(courseGroupId) {
			let response = await getLessonGroupTips({ courseGroupId });
			this.tipList = response.data;
		},
	},
};
</script>

<style lang="less" scoped>
.step3 {
	.pay-way {
		width: 1130px;
		// height: 162px;
		background: #ffffff;
		border: 1px solid #e8e8e8;
		border-radius: 4px;
		// display: flex;
		// flex-direction: column;
		// justify-content: center;
		// align-items: center;
		padding: 34px 30px 40px;
		margin-bottom: 34px;
		.pay-tip {
			width: 101px;
			height: 16px;
			font-size: 14px;
			font-weight: bold;
			color: #5b6b73;
			line-height: 16px;
			margin-bottom: 27px;
		}
		.payway-list {
			display: flex;
      margin-bottom: 40px;
			.payway-item {
				width: 180px;
				height: 46px;
				border: 1px solid #e6e6e6;
				border-radius: 4px;
				position: relative;
				overflow: hidden;
				cursor: pointer;
				display: flex;
				justify-content: center;
				align-items: center;
				margin-right: 50px;
				img {
					width: 38px;
					margin-right: 15px;
				}
				.pi-tag {
					width: 28px;
					height: 28px;
					background: url('../../../assets/images/icon/select.png')
						no-repeat;
					background-size: contain;
					position: absolute;
					top: 0;
					right: 0;
					opacity: 0;
				}
			}
			.payway-item-active {
				border: 1px solid #fb4a3e;
				.pi-tag {
					opacity: 1;
				}
			}
		}
	}
  .pay {
		display: flex;
		width: 100%;
		align-items: center;
		
		.pay-item {
			width: 180px;
			height: 46px;
			line-height: 46px;
			background: #ffffff;
			border: 1px solid #d8d8d8;
			border-radius: 4px;
			text-align: center;
			cursor: pointer;
			margin-right: 50px;
			position: relative;
			overflow: hidden;
			font-size: 16px;
			color: #333;
			.tag {
				position: absolute;
				top: 0;
				right: 0;
				width: 28px;
				height: 28px;
				// background: #FF3F47;
				background: url('../../../assets/images/icon/select.png')
					no-repeat;
				background-size: contain;
				opacity: 0;
			}
		}
		.pay-item-active {
			border: 1px solid #ff3f47;
			opacity: 1;
			.tag {
				opacity: 1 !important;
			}
		}
	}
	.pay-state {
		width: 1130px;
		height: 339px;
		background: #ffffff;
		border: 1px solid #e8e8e8;
		border-radius: 4px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin-bottom: 20px;
		.state-icon {
			display: block;
			width: 70px;
			height: 70px;
			// background: #eee;
			margin-bottom: 20px;
		}
		.pay-tip {
			font-size: 20px;
			font-weight: bold;
			color: #333333;
			line-height: 14px;
			margin-bottom: 29px;
		}
		.confirm-tip {
			font-size: 16px;
			font-weight: 400;
			color: #333333;
			line-height: 14px;
			margin-bottom: 30px;
		}
		.btn {
			width: 150px;
			height: 42px;
			line-height: 42px;
			background: #2e8df4;
			border-radius: 4px;
			text-align: center;
			color: #ffffff;
		}
	}
}
.pay-bottom {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	color: #333;
	font-size: 16px;
	padding: 20px 0;
	span {
		margin-right: 10px;
	}
	.confirm-btn {
		width: 148px;
		height: 40px;
		background: #ff3f47;
		border-radius: 6px;
		color: #fff;
		margin-left: 20px;
	}
}
</style>
