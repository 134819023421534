<template>
  <div class="steps">
    <template v-for="(item, index) of list">
      <div v-if="index === 0" :key="index" :style="moveList[index]" class="step-item step1" :class="{'step1-active' : item.key === nowStep }">{{item.key}}、{{item.label}}</div>
      <div v-if="index !== 0" :key="index" :style="moveList[index]" class="step-item" :class="{'active-item' : item.key === nowStep }">{{item.key}}、{{item.label}}</div>
    </template>
    
    <!-- <div class="step-item">1、选择职位</div>
    <div class="step-item">1、选择职位</div>
    <div class="step-item">1、选择职位</div> -->
  </div>
</template>

<script>
export default {
  name: "Step",
  props:{
    list: {
      type:Array,
      default: () => {
        return []
      }
    },
    nowStep: {
      type:Number,
      default: 1
    }
  },
  data() {
    return {
      // list: [
      //   {label: '选择职位', key:1},
      //   {label: '确认报名', key:2},
      //   {label: '系统审核', key:3},
      //   {label: '在线支付', key:4}
      // ],
      moveList: []
    }
  },
  mounted() {
    this.list.forEach((item,index) => {
      this.moveList.push({
        transform: `translateX(-${15 * +index}px)`
      })
    })
  }
}
</script>

<style lang="less" scoped>
.steps{
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  .step-item{
    background: url("../../assets/images/courseGroup/step.png") no-repeat;
    background-size: contain;
    height: 33px;
    line-height: 33px;
    text-align: center;
    width: 169px;
    font-size: 14px;
    font-weight: 400;
    color: #525E71;
  }
  .active-item{
    background: url("../../assets/images/courseGroup/step-active.png") no-repeat;
    background-size: contain;
    transform: translateZ(-20px);
    color: #fff;
  }
  .step1{
    background: url("../../assets/images/courseGroup/step1.png") no-repeat;
    background-size: contain;
  }
  .step1-active{
    background: url("../../assets/images/courseGroup/step1-active.png") no-repeat;
    background-size: contain;
    color: #fff;
  }
}
</style>

