<template>
  <div class="app-page-1">
    <div class="app-content">
      <div class="signup">
        <!-- 进度条 -->
        
        <div class="step" v-if="showStep">
          <p class="step-title">报名流程</p>
          <Step :list="stepList" :nowStep="nowStep" />
        </div>
        
        <div class="space" v-if="orderDetail && orderDetail.type !== 1"></div>
        <!-- 步骤1 -->
        <template v-if="nowStep === 1">
          <Step1  />
        </template>
        <template v-if="nowStep === 2">
          <Step2 />
        </template>
        <template v-if="nowStep === 3">
          <Step3 />
        </template>
        <template v-if="nowStep === 4">
          <Step3 />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { getOrderDetail } from '@/api/api'
import Step from '@/components/courseGroup/Step.vue'
import Step1 from '@/components/courseGroup/steps/Step1'
import Step2 from '@/components/courseGroup/steps/Step2'
import Step3 from '@/components/courseGroup/steps/Step3'
import Step4 from '@/components/courseGroup/steps/Step4'
export default {
  name: "SignUp",
  // middleware: 'accountVerification',
  // async asyncData(context) {
  //   let nowStep = +context.query.step || 1
  //   let returnObj = {
  //     nowStep: nowStep,
  //     stepList: [{label: '选择职位', key:1},
  //       {label: '确认报名', key:2},
  //       {label: '系统审核', key:3},
  //       {label: '在线支付', key:4}],
  //     courseGroupId: context.query.course_group_id,
  //     jobListId: context.query.job_list_id,
  //   }
  //   return returnObj
  // },
  data() {
    return {
      courseGroupId: '',
      jobListId: '',
      orderDetail: null,
      orderInfoStr: null,
      nowStep: 1,
      stepList: [
        {label: '选择职位', key:1},
        {label: '确认报名', key:2},
        {label: '系统审核', key:3},
        {label: '在线支付', key:4}
      ],
      courseGroupId: null,
      jobListId: null,
    }
  },
  components: {
    Step,
    Step1,
    Step2,
    Step3,
    Step4
  },
  
  mounted() {
    
    // this.courseGroupId = this.$route.query.course_group_id
    // this.jobListId = this.$route.query.job_list_id
    // let orderInfoStr = localStorage.getItem('orderInfo') || ''
    // if(orderInfoStr) {
    //   this.cursorInfo =  JSON.parse(orderInfoStr).loCourseInfo
    // } else {
    //   this.cursorInfo = null
    // }
    // if(!this.$store.state.orderInfo) {
    //   this.$store.replace({path: '/course'})
    // }
    this.jobListId = this.$route.query.job_list_id
    this.nowStep = this.$route.query.step ? +this.$route.query.step : 1
    this.orderId = this.$route.query.orderId
    // this.orderInfoStr = window.localStorage.getItem('orderInfo') || ''
    if(this.orderId) {
      this.getOrderDetail()
    }
  },
  watch: {
    '$route': {
      handler() {
        this.nowStep = this.$route.query.step ? +this.$route.query.step : 1
        //深度监听，同时也可监听到param参数变化
        if(this.$route.query.step && (+this.$route.query.step === 3 || +this.$route.query.step === 4)) {
          window.location.reload()
        }
      },
      deep: true,
    }
  },
  methods: {
    async getOrderDetail() {
      let params = {
        orderId: this.orderId
      }
      let response = await getOrderDetail(params);
      if(+response.returnCode !== 10001) {
        this.$message.error(response.returnMsg)
        return {}
      }
      this.orderDetail = response.data
      return response.data
      // this.getLessonGroupTips(this.orderDetail.courseGroupId)
    },
    // async init() {
    //   let response = await getJobPlaceName({ids: this.jobListId})
    //   if(+response.returnCode !== 10001) {

    //     return false
    //   }
    // }
  },
  computed: {
    showStep() {
      let state = false
      if(this.nowStep === 1) {
        state = true
      } else if(this.nowStep === 2) {
        // 获取信息
        if(this.$store.state.orderInfo) {
          state = this.$store.state.orderInfo.loCourseInfo.interviewFlag === 1
        } else {
          state = false
        }
        // if(this.orderInfoStr) {
        //   state = JSON.parse(this.orderInfoStr).loCourseInfo.interviewFlag === 1
        // } else {
        //   state = false
        // }
      } else if(this.nowStep === 3 || this.nowStep === 4) {
        if(this.orderDetail) {
          state = this.orderDetail.type === 1
        }
      }
      return state
    }
  }
}
</script>

<style lang="less" scoped>
.app-page-1{
  background:#F3F3F3;
}
.app-content{
  overflow: hidden;
}
.signup{
  background:#fff;
  padding:20px 35px 20px 35px;
  min-height: 80vh;
  margin:30px 0;
}
.step{
  height: 68px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
  border-bottom: 1px solid #E8E8E8;
  margin-bottom: 20px;
  .step-title{
    font-size: 20px;
    font-weight: bold;
    color: #333333;
    line-height: 21px;
  }
}
//  步骤 2
.space{
  height: 50px;
}

</style>